<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Create a Campaign</p>

        <v-row>
          <v-col cols="10" class="py-0 my-1">
            <v-text-field
              v-model="userName"
              ref="userName"
              outlined dense
              label="Search username, phone number, unique code..."
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="py-0 my-1">
            <v-btn
              color="success"
              class="me-3"
              fab small
              @click="getUsers()"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedUser"
              ref="selectedUser"
              :items="users"
              item-text="username"
              item-value="id"
              outlined dense
              label="Select a user"
            ></v-select>
          </v-col>
          <v-col
            cols="6" xs="6"
            class="py-0 my-1"
          >
            <v-text-field class="pa-0 mt-3"
              ref="campaign_name" v-model="campaignName"
              label="Enter Campaign Name"
              :rules="[v => !!v || 'Campaign Name is required']"
              color="primary"
            ></v-text-field>
          </v-col>
          <v-col
            cols="6" xs="6"
            class="py-0 my-1"
          >
            <v-select
              @change="OnChangeCampaignType()"
              class="pt-0 mt-3" color="primary"
              v-model="campaignType" ref="campaign_type"
              :items="campaignTypes"
              :rules="[v => !!v || 'Campaign Types is required']"
              label="Select Campaign Types"
              required
            ></v-select>
          </v-col>
          <v-col
            cols="6" xs="6"
            class="py-0 my-1"
          >
            <v-text-field class="pa-0 mt-3"
              ref="campaign_target_amount"
              label="Campaign Amount" v-model="campaignTargetAmount"
              :disabled='isAmountDisabled'  :required='isAmountRequired'
              color="primary"
            ></v-text-field>
          </v-col>
          <v-col
            cols="6" xs="6"
            class="py-0 my-1"
          >
            <v-text-field class="pa-0 mt-3"
              ref="campaign_target_date"
              label="Campaign Date" v-model="campaignTargetDate"
              :disabled='isDateDisabled' :required='isDateRequired'
              type="date"
              color="primary"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-textarea class="pa-0 mt-3"
              name="input-7-1"
              auto-grow  v-model="campaignDescription"
              ref="campaign_description"
              label="Campaign Description"
              :rules="[v => !!v || 'Campaign Description is required']"
              background-color="grey lighten-4"  color="primary"
              rows="2" 
              row-height="20" 
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn 
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="createCampaign()"
        >
          Create Campaign
        </v-btn>

      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'
// eslint-disable-next-line object-curly-newline
import Spinner from "@/components/Spinner.vue";


export default {
  components: {
    Spinner,
  },

  data() {
    return {
      loading: false,
      userName: '',
      selectedUser: '',
      users: [],
      isAmountDisabled: false,
      isDateDisabled: false,
      isAmountRequired: false,
      isDateRequired: false,
      campaign_name_help: false,
      campaign_type_help: false,
      campaign_amount_help: false,
      campaign_date_help: false,
      campaign_description_help: false,
      campaignName: "",
      campaignType: "",
      campaignTargetAmount: "",
      campaignTargetDate: "",
      campaignDescription: "",
      campaignTypes: [],
    }
  },
  mounted(){
    this.getCampaignTypes();
    document.title = "Manager: Savings | Create Campaign"
  },

  methods:{
    getUsers(){
      axios
        .get('/api/v1/manager/get/clients/'+this.userName+'/')
        .then(response => {
          this.users = response.data  // get the data and fill into campaigns
          console.log(this.users)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async getCampaignTypes(){
      this.loading = true
      
      await axios
        .get('/api/v1/manager/get/campaign/types/')
        .then(response => {
            this.campaignTypes = response.data  // get the data and fill into campaigns
        })
        .catch(error => {
            if (error.response){
                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            }else{
                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            }
        })

      this.loading = false
    },

    async createCampaign(){
        var future = new Date();
        future.setDate(future.getDate() + 30);  // sets the future to 30 days from now
        console.log(future)
        
        if(this.campaignName === ""){
            this.$store.commit('setSnackBarMessage', "Please fill in the campaign name")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.campaign_name.focus()  // this causes a an auto fucos to the element
        }
        else if(!this.selectedUser){
            this.$store.commit('setSnackBarMessage', "Please select a user")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.selectedUser.focus()  // this causes a an auto fucos to the element
        }
        else if(this.campaignType === ""){
            this.$store.commit('setSnackBarMessage', "Please select the campaign type")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.campaign_type.focus()  // this causes a an auto fucos to the element
        }
        else if ((this.campaignType === "MONEY_TARGET" || this.campaignType === "MONEY_TIME_TARGET") && Number(this.campaignTargetAmount) < 5000){
            this.$store.commit('setSnackBarMessage', "Please your campaign target amount should be from 5000 F CFA and above.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.campaign_target_amount.focus()  // this causes a an auto fucos to the element
        }
        else if ((this.campaignType === "TIME_TARGET" || this.campaignType === "MONEY_TIME_TARGET") && new Date(this.campaignTargetDate) < future){
            this.$store.commit('setSnackBarMessage', "Sorry your campaign date must be atleast <b>1 month from now</b>.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.campaign_target_date.focus()  // this causes a an auto fucos to the element
        }
        else if(this.campaignType === "MONEY_TARGET" && this.campaignTargetAmount === ""){
            this.$store.commit('setSnackBarMessage', "Please input your campaign target amount")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.campaign_target_amount.focus()  // this causes a an auto fucos to the element
        }
        else if(this.campaignType === "TIME_TARGET" && this.campaignTargetDate === ""){
            this.$store.commit('setSnackBarMessage', "Please select your campaign target date")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.campaign_target_date.focus()  // this causes a an auto fucos to the element
        }
        else if(this.campaignType === "MONEY_TIME_TARGET" && this.campaignTargetDate === ""){
            this.$store.commit('setSnackBarMessage', "Please select your campaign target date")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.campaign_target_date.focus()  // this causes a an auto fucos to the element
        }
        else if(this.campaignType === "MONEY_TIME_TARGET" && this.campaignTargetAmount === ""){
            this.$store.commit('setSnackBarMessage', "Please input your campaign target amount")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.campaign_target_amount.focus()  // this causes a an auto fucos to the element
        }
        else if(this.campaignDescription === ""){
            this.$store.commit('setSnackBarMessage', "Please input your campaign description")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.campaign_description.focus()  // this causes a an auto fucos to the element
        }
        else {
            this.loading = true
            let formData = {
              'user_id': this.selectedUser, 
              'campaignName': this.campaignName, 
              'campaignType': this.campaignType, 
              'campaignTargetAmount': this.campaignTargetAmount, 
              'campaignTargetDate': this.campaignTargetDate, 
              'campaignDescription': this.campaignDescription
            }

            await axios
                .post('/api/v1/manager/create/new_campaign/', formData)
                .then(response => {
                    this.$store.commit('setSnackBarMessage', response.data[0].text)
                    this.$store.commit('setSnackBarColor', response.data[0].color)
                    this.$store.commit('activateSnackBar', true)
                    if(response.data[0].id !== 0){
                        let router = this.$router
                        setTimeout(function(){
                            router.push('/savings/clients/campaigns')
                        }, 4000)
                    }
                })
                .catch(error => {
                    if (error.response){
                        this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }else{
                        this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                })
            this.loading = false
        }
    },

    OnChangeCampaignType(){
        console.log(this.campaignType)
        if(this.campaignType === "MONEY_TARGET"){
            this.isAmountDisabled = false
            this.isDateDisabled = true                    
            this.isAmountRequired = false
            this.isDateRequired = true                    
        }
        else if(this.campaignType === "TIME_TARGET"){
            this.isAmountDisabled = true
            this.isDateDisabled = false                                        
            this.isAmountRequired = true
            this.isDateRequired = false                                        
        }
        else if(this.campaignType === "MONEY_TIME_TARGET"){
            this.isAmountDisabled = false
            this.isDateDisabled = false                                        
            this.isAmountRequired = false
            this.isDateRequired = false                                        
        }
        else if(this.campaignType === "UNDEFINED TARGET"){
            this.isAmountDisabled = true
            this.isDateDisabled = true                                        
            this.isAmountRequired = true
            this.isDateRequired = true                                        
        }
    },

  },
}
</script>
